<template>
    <div class="w-100">
        <h5 class=" text-primary"><strong>Software Installed</strong></h5>
        <div class="mr-1 ml-1">
            <div>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th style="width: 10px">#</th>
                            <th style="width: 1%;">Software</th>
                            <th>Ratio</th>
                            <th style="width: 40px">Label</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="element.id" v-for="(element, index) in pageOfItems">
                            <td><i class="el-icon-takeaway-box text-primary" :title="index"></i></td>
                            <td class="text-nowrap">{{element.installedSoftwaresDisplayName}} (<strong>{{element.count}}/{{softwareInstalledTotal}}</strong>)</td>
                            <td class="v-center">
                                <div class="progress progress-xs">
                                    <div class="progress-bar progress-bar-danger" :style="`width: ${Math.round((element.count/softwareInstalledTotal*100 + Number.EPSILON) * 100) / 100}%`"></div>
                                </div>
                            </td>
                            <td>
                                <span class="badge bg-danger">
                                    {{Math.round((element.count/softwareInstalledTotal*100 + Number.EPSILON) * 100) / 100}}%
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <jw-pagination :items="softwareInstalled" @changePage="onChangePage" :pageSize="15"></jw-pagination>
    </div>
</template>

<script>
    import chartDataApi from '@/api/common/chartData';
    import JwPagination from 'jw-vue-pagination';
    export default {
        data() {
            return {                
                softwareInstalled: [],
                pageOfItems: []
            }
        },  
        components: {
            "jw-pagination" : JwPagination
        },
        computed: {
            softwareInstalledTotal() {
                return this.softwareInstalled.reduce((a, b) => a + b.count, 0);
            }
        },        
        mounted() {
            this.getSoftwareInstalledStatistics();
        },
        methods: {            
            getSoftwareInstalledStatistics() {
                chartDataApi.getSoftwareInstalledStatistics().then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.softwareInstalled = response.data.data;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            }
        }
    }
</script>